// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)

.table-container {
    position: relative;
}

.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .table-action {
        .action-icon {
            color: $gray-600;
            font-size: 1.2rem;
            display: inline-block;
            padding: 0 3px;

            &:hover {
                color: $gray-700;
            }
        }
    }

    &.loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: -25px;
        z-index: 999;
        background: rgba(255,255,255,0.95);
        display: none;

        img {
            width: auto;
            height: auto;
            max-width: 16px;
            max-height: 16px;
            position: relative;
            top: calc(50% - 8px);
            left: calc(50% - 8px);
        }

        &.active {
            display: block;
        }
    }
}

// For new Table
tr.new {
    font-weight: bold;
    color: $dark;
    background: lighten($yellow, 30%);

    .id {
        color: $red;
        &::before {
            content: "\F4CE";
            font: normal normal normal 16px/1 "Material Design Icons";
        }
    }
}

// For Flagged Table
tr.flagged {
    font-weight: bold;
    color: $dark;
    background: lighten($red, 30%);

    .id {
        color: $red;
        &::before {
            content: "\F23B";
            font: normal normal normal 16px/1 "Material Design Icons";
        }
    }
}
