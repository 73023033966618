// 
// badge.scss
//

.badge {
    color: $light;

    &.active::before {
        content: '\F4CF';
        font-family: "Material Design Icons";
        padding-right: 0.3rem;
    }
}

.badge-light {
    color: $gray-800;
}
