//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

.form-group {
    label {
        color: $gray-900;
    }
    .form-control {
        color: $gray-900;
        border-color: $gray-900;

        &::placeholder {
            color: $gray-700;
        }

        &:placeholder-shown {
            border-color: $gray-900;
        }

        &:disabled {
            color: $gray-500;
            border-color: $gray-500;
        }
    }
    .custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
    .custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
        &:focus {
            box-shadow: none !important;
        }
    }
    .custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid {
        border-color: $red;
    }
}